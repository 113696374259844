import request from '@/utils/request';

export const getCurrencyDetail = params =>
  request.get('/exchange-api/wallet/myTradeInfo', { params });

export const exportTradeInfoDetail = params =>
  request.post('/exchange-api/wallet/exportTradeInfoDetail', params, {
    responseType: 'blob',
  });

export const exportTradeInfoDetailXls = params =>
  request.post('/exchange-api/wallet/exportTradeInfoDetailXls', params, {
    responseType: 'blob',
  });
