<template>
  <div class="container">
    <el-card>
      <div class="header">{{ $t('check_detail_msg1') }}</div>
      <el-form
        ref="form"
        inline
        size="small"
        :model="form"
        style="margin-top: 20px"
      >
        <el-form-item :label="`${$t('home_page_title5')}:`" prop="currency">
          <el-select
            v-model="form.currency"
            :placeholder="$t('incoming_company_select_placeholder')"
          >
            <el-option
              v-for="c in currency_list"
              :key="c.value"
              :label="c.txt"
              :value="c.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="`${$t('check_detail_msg2')}:`">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            :range-separator="$t('incoming_company_date_placeholder1')"
            :start-placeholder="$t('incoming_company_date_placeholder2')"
            :end-placeholder="$t('incoming_company_date_placeholder3')"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 400px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">
            {{ $t('check_detail_msg3') }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleDownLoad(false)">
            {{ $t('check_detail_msg4') }}
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleDownLoad(true)">
            {{ $t('check_detail_msg12') }}
          </el-button>
        </el-form-item>
      </el-form>
      <!--      table-->
      <el-table :data="data" size="small">
        <el-table-column
          prop="updateTime"
          :label="$t('check_detail_msg5')"
        ></el-table-column>
        <el-table-column
          prop="currency"
          :label="$t('home_page_title5')"
        ></el-table-column>
        <el-table-column
          prop="revenue"
          :label="$t('check_detail_msg6')"
        ></el-table-column>
        <el-table-column
          prop="expend"
          :label="$t('check_detail_msg7')"
        ></el-table-column>
        <el-table-column
          prop="balance"
          :label="$t('home_page_title1')"
        ></el-table-column>
        <el-table-column
          prop="bbal"
          :label="$t('check_detail_msg8')"
        ></el-table-column>
        <el-table-column
          prop="typeName"
          :label="$t('check_detail_msg9')"
        ></el-table-column>
        <el-table-column
          prop="common"
          :label="$t('check_detail_msg10')"
        ></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pages.total"
        style="margin-top: 20px; text-align: right"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import {
  exportTradeInfoDetail,
  getCurrencyDetail,
  exportTradeInfoDetailXls,
} from './api';
import mixins from './mixins';
export default {
  name: 'check-detail',
  mixins: [mixins],
  data() {
    return {
      data: [],
      form: { currency: '', date: '' },
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.form.currency = this.$route.query.currency;
    this._getCurrencyDetail();
  },
  methods: {
    async handleDownLoad(isXls = false) {
      const params = {
        currency: this.form.currency,
        startDate: (this.form.date && this.form.date[0]) || '',
        endDate: (this.form.date && this.form.date[1]) || '',
      };
      if (isXls) {
        await exportTradeInfoDetailXls(params);
      } else {
        await exportTradeInfoDetail(params);
      }
      this.$message.success(this.$t('check_detail_msg11'));
    },
    async _getCurrencyDetail() {
      const _params = {
        ...this.pages,
        ...this.form,
      };
      _params.startDate = (this.form.date && this.form.date[0]) || '';
      _params.endDate = (this.form.date && this.form.date[1]) || '';
      delete _params['total'];
      delete _params['date'];
      const { dataSet, pages } = await getCurrencyDetail(_params);
      this.data = dataSet;
      this.pages = pages;
    },
    handleCurrentChange(val) {
      this.pages = { ...this.pages, pageNum: val };
      this._getCurrencyDetail();
    },
    handleSearch() {
      this._getCurrencyDetail();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  padding: 16px 24px 0 24px;
  .header {
    height: 40px;
    border-bottom: 2px solid #e9e9e9;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
